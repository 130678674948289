<template>
  <div>
    <el-card>
      <addHeader @submit="submit" @cancel="cancel" :statusShow="false" />
      <el-form
        ref="addCardFormRef"
        :model="addCardForm"
        label-width="120px"
        :rules="addCardFormRules"
      >
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item
              label="成品采购号:"
              size="small"
              prop="podr_no"
            >
              <el-input
                placeholder="请输入采购号码"
                v-model="addCardForm.podr_no"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="客户货号:" size="small" prop="cust_artno">
              <el-input
                placeholder="请输入客户货号"
                v-model="addCardForm.cust_artno"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品编号:" size="small" prop="prod_no">
              <el-input
                placeholder="请输入产品编号"
                v-model="addCardForm.prod_no"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="vg_pt_8">
          <el-col :span="8">
            <el-form-item label="产品名称:" size="small" prop="prod_name">
              <el-input
                placeholder="请输入产品名称"
                v-model="addCardForm.prod_name"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品数量:" size="small" prop="prod_total_num">
              <el-input
                placeholder="请输入产品数量"
                :disabled="inputFlag"
                v-model="addCardForm.prod_total_num"
                @change="roundsChange"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="需要经历的金检步骤:" size="small" prop="rfid_step_copy">
              <el-checkbox-group v-model="addCardForm.rfid_step_copy" @change="rfidStepChange">
                <el-checkbox label="入库前金检">入库前金检</el-checkbox>
                <el-checkbox label="机缝皮壳后">机缝皮壳后</el-checkbox>
                <el-checkbox label="手工半成品">手工半成品</el-checkbox>
                <el-checkbox label="成品">成品</el-checkbox>
                <el-checkbox disabled label="整箱">整箱</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="vg_pt_8">
          <el-col :span="8">
            <el-form-item
              label="每筐可装数量:"
              size="small"
              prop="podr_prod_num"
            >
              <el-input
                maxlength="5"
                :disabled="inputFlag"
                show-word-limit
                placeholder="请输入每筐可装数量"
                v-model.Number="addCardForm.podr_prod_num"
                @change="roundsChange"
                @input="
                  addCardForm.podr_prod_num = helper.keepTNum(
                    addCardForm.podr_prod_num
                  )
                "
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="新增筐数:" size="small" prop="rounds">
              <el-input
                maxlength="6"
                show-word-limit
                :disabled="inputFlag"
                placeholder="请输入新增筐数"
                v-model="addCardForm.rounds"
                @input="
                  addCardForm.rounds = helper.keepTNum(addCardForm.rounds)
                "
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button :disabled="inputFlag" type="primary" size="small" @click="addCardRounds"
              >新增{{ addCardForm.rounds || 0 }}筐</el-button
            >
          </el-col>
        </el-row>
        <el-row class="vd_mt_20 vd_bt" :gutter="20">
          <el-col :span="8">
            <el-form-item label="刷卡卡号:" size="small">
              <el-input
                maxlength="10"
                show-word-limit
                placeholder="请刷卡"
                v-model="brushCard"
                @input="brushCard = helper.keepTNum(brushCard)"
                @keyup.native.enter="brushCardKeyup"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="已开卡数量:"
              size="small"
              label-width="130px"
            >
              <el-input disabled v-model="totalNum"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-button
              type="danger"
              size="mini"
              :disabled="rfid_obj.rfid_no_list.length == 0"
              @click="del"
              plain
              >删除</el-button
            >
          </el-col>
        </el-row>
      </el-form>
      <el-form ref="tabFromRef" :model="rfid_obj">
        <el-table
          class="vd_mt_20"
          :data="rfid_obj.rfid_no_list"
          style="width: 100%"
          border
          @select="selectTab"
          @select-all="selectTab"
          @selection-change="selectTab"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column type="index" label="序号"></el-table-column>

          <el-table-column>
            <template slot="header">
              <span class="vg_deep_red">每筐可装数量</span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="'rfid_no_list[' + scope.$index + '].podr_prod_num'"
                :rules="[{ required: true }]"
              >
                <el-input
                  v-model.number="
                    rfid_obj.rfid_no_list[scope.$index].podr_prod_num
                  "
                  disabled
                  size="small"
                  maxlength="5"
                  show-word-limit
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="rfid_no" label="卡号">
            <template slot="header">
              <span class="vg_deep_red">卡号</span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="'rfid_no_list[' + scope.$index + '].rfid_no'"
                :rules="[{ required: true }]"
              >
                <el-input
                  v-model.number="scope.row.rfid_no"
                   :ref="'inpArea'+scope.$index"
                  size="small"
                  maxlength="10"
                  show-word-limit
                  clearable
                  @change="rfidChange(scope.$index)"
                  @blur='rfidBlur(scope.$index)'
                  :disabled="scope.row.isDis"
                >
                  <template slot="append">
                    <el-button
                      v-if="scope.row.isDis"
                      size="mini"
                      @click="editRfid(scope.$index)"
                      >修改</el-button
                    >
                    <el-button v-else size="mini" @click="resRfid(scope.$index)"
                      >确认修改</el-button
                    >
                  </template>
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.rfid_no">已开卡</el-tag>
              <el-tag v-else>未开卡</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { openAPI } from "@/api/modules/open";
import { podtAPI } from "@/api/modules/podt";
import { get, post } from "@/api/request";
import addHeader from "@/views/component/addHeader.vue";
export default {
  name: "OpenCartAddMain",
  components: {
    addHeader,
  },
  data() {
    return {
      addCardForm: {
        podr_no: "",
        prod_no: "",
        cust_artno: "",
        prod_name: "",
        prod_total_num: "",
        podr_prod_num: "",
        rfid_step_copy: ['整箱'],
        rfid_step: "0,0,0,0,1",
        rounds: "",
        // prod_total_num: "",
        // prod_num: "",
      },
      addCardFormRules: {
        podr_no: [
          { required: true, message: " ", trigger: "change" },
        ],
        cust_artno: [
          { required: true, message: " ", trigger: "change" },
        ],
        prod_name: [
          { required: true, message: " ", trigger: "change" },
        ],
        podr_prod_num: [
          { required: true, message: " ", trigger: "change" },
        ],
        rfid_step_copy: [
          { required: true, message: " ", trigger: "change" },
        ],
        rounds: [
          { required: true, message: " ", trigger: "change" },
        ],
        prod_total_num: [
          { required: true, message: " ", trigger: "change" },
        ],
      },
      options: [],
      rfidArr: [],
      brushCard: "",
      rfid_obj: { rfid_no_list: [] },
      userInfo: {},
      multipleSelection: [],
      checkList:[],
      inputFlag:false
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getUserInfo();
      this.get_all_podts_v1();
    },
    // 获取用户信息
    getUserInfo() {
      this.userInfo = this.$cookies.get("userInfo");
    },
    //提交
    submit() {
      // 判断 每筐可装数量 和 卡号是否填写
      let rfid_objFlag = false;
      this.$refs["tabFromRef"].validate((valid) => {
        if (!valid) {
          rfid_objFlag = true;
        }
      });
      if (rfid_objFlag) {
        return;
      }

      // 判断产品编号是否选择
      this.$refs["addCardFormRef"].validate((valid) => {
        if (valid) {
          //判断筐数
          if (this.rfid_obj.rfid_no_list.length === 0) {
            return this.$message.warning("至少增加一筐");
          }

          // if (this.addCardForm.prod_num < this.totalNum) {
          //   return this.$message.warning("新增总数量超过订单产品总数");
          // }
          let addCardForm = JSON.parse(JSON.stringify(this.addCardForm))
          addCardForm.stff_name = this.userInfo.stff_name;
          addCardForm.stff_id = this.userInfo.stff_id;
          addCardForm.user_id = this.userInfo.user_id;
          addCardForm.podr_id = this.userInfo.podr_id;
          addCardForm.rfid_no_list = this.rfid_obj.rfid_no_list;
          // {
          //       stff_name: this.userInfo.stff_name,
          //       stff_id: this.userInfo.stff_id,
          //       user_id: this.userInfo.user_id,
          //       podr_id: this.addCardForm.order_no,
          //       podr_prod_id: this.addCardForm.prod_no,
          //       podr_prod_num: this.addCardForm.podr_prod_num,
          //       rfid_no_list: this.rfid_obj.rfid_no_list,
          //     }
          this.$confirm("确认提交?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              post(openAPI.add_rfid, addCardForm)
                .then((res) => {
                  if (res.data.code !== 0) {
                    return this.$message.error(res.data.msg);
                  }
                  this.inputFlag = false
                  this.brushCard = null
                  this.$message.success(res.data.msg);
                  this.$refs.addCardFormRef.resetFields();
                  this.rfidArr = [];
                  this.rfid_obj.rfid_no_list = [];
                })
                .catch((res) => {
                  let msg = res.data.msg;
                  this.$message.error(msg);
                });
            })
            .catch(() => {
              //点击取消
            });
        }
      });
    },
    //取消
    cancel() {
      this.$confirm("是否取消新增?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.jump("open_list");
          this.$message({
            type: "info",
            message: "已取消新增!",
          });
        })
        .catch((res) => {});
    },
    //获取产品编号
    get_all_podts_v1() {
      get(podtAPI.get_all_podts_v1)
        .then((res) => {
          if (res.data.code !== 0) {
            return this.$message.error(res.data.smg);
          }
          this.options = res.data.data;
        })
        .catch((res) => {
          return this.$message.error(res.data.msg);
        });
    },
    // 计算
    roundsChange(){
      if(Number(this.addCardForm.podr_prod_num) !== 0 && this.addCardForm.podr_prod_num){
        this.addCardForm.rounds = Math.ceil(Number(this.addCardForm.prod_total_num)/Number(this.addCardForm.podr_prod_num))
      }
    },
    rfidStepChange(e){
      console.log('e',e);
      let temp = [0,0,0,0,1];
      e.forEach(item=>{
        if(item === '入库前金检'){
          temp[0] = 1
        }
        if(item === '机缝皮壳后'){
          temp[1] = 1
        }
        if(item === '手工半成品'){
          temp[2] = 1
        }
        if(item === '成品'){
          temp[3] = 1
        }
      })
      this.addCardForm.rfid_step = temp.join(',')
    },
    //刷卡
    brushCardKeyup() {
      //判断是否输入卡号
      if (!this.brushCard) {
        return;
      }

      if (this.rfid_obj.rfid_no_list.length === 0) {
        return this.$message.warning("请先添加新增框");
      }

      //判断 卡号是否已满
      let fullStatus = false;
      fullStatus = this.rfid_obj.rfid_no_list.every((item) => {
        return item.rfid_no;
      });
      if (fullStatus) {
        return this.$message.warning("卡号已满");
      }

      let status = this.rfid_obj.rfid_no_list.some((item) => {
        return item.rfid_no == this.brushCard;
      });
      if (status) {
        return this.$message.warning("该卡号已存在");
      }

      get(openAPI.get_rfid_by_no, { rfid_no: this.brushCard })
        .then((res) => {
          if (res.data.code == 2) {
            for (let i in this.rfid_obj.rfid_no_list) {
              if (!this.rfid_obj.rfid_no_list[i].rfid_no) {
                this.rfid_obj.rfid_no_list[i].rfid_no = this.brushCard;
                this.rfidArr.push(this.brushCard);
                this.brushCard = "";
                return;
              }
            }
            this.$message.warning("卡号已满");
          } else {
            this.$message.warning("该卡号已使用");
          }
        })
    },
    //选择产品编号
    prod_noChange(val) {
      if (!val) {
        // this.addCardForm.prod_num = "";
        this.addCardForm.order_no = "";
        return;
      }

      let arr = this.options.filter((item) => {
        return item.podr_prod_id == val;
      });
      // this.addCardForm.prod_num = arr[0].prod_num;
      this.addCardForm.order_no = arr[0].podr_id;

      let numArr = [];
      let total = 0;
      this.rfid_obj.rfid_no_list.forEach((item) => {
        numArr.push(item.podr_prod_num - 0);
      });
      total = numArr.reduce((pre, next) => {
        return pre + next;
      }, 0);

      // if (total > this.addCardForm.prod_num) {
      //   this.$message.warning("新增总数量超过订单产品总数");
      // }
    },
    //新增筐数
    addCardRounds() {
      if (!this.addCardForm.rounds || this.addCardForm.rounds == 0) {
        return this.$message.warning("至少添加一筐");
      }
      for (let i = 0; i < this.addCardForm.rounds - 0; i++) {
        this.rfid_obj.rfid_no_list.push({
          podr_prod_num: this.addCardForm.podr_prod_num,
          rfid_no: "",
          isDis: true,
        });
      }

      // if (this.addCardForm.prod_num) {
      //   let numArr = [];
      //   let total = 0;
      //   this.rfid_obj.rfid_no_list.forEach((item) => {
      //     numArr.push(item.podr_prod_num - 0);
      //   });
      //   total = numArr.reduce((pre, next) => {
      //     return pre + next;
      //   }, 0);

      //   if (total > this.addCardForm.prod_num) {
      //     this.$message.warning("新增总数量超过订单产品总数");
      //   }
      // }
      // this.addCardForm.rounds = "";
      // this.addCardForm.podr_prod_num = "";
      this.inputFlag = true;
    },
    //表格选择
    selectTab(val) {
      this.multipleSelection = val;
    },

    //删除每一行
    del() {
      if (this.multipleSelection.length == 0) {
        return this.$message.warning("至少选择一条数据");
      }
      this.$confirm("确认删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.multipleSelection.forEach((item) => {
            let i = this.rfid_obj.rfid_no_list.indexOf(item);
            this.rfid_obj.rfid_no_list.splice(i, 1);
          });
          if(this.rfid_obj.rfid_no_list.length === 0){
            this.inputFlag = false
          }
        })
        .catch((res) => {});
    },
    rfidBlur(i){
      setTimeout(()=>{
            this.rfid_obj.rfid_no_list[i].isDis  = true;
      },200)
      
      
    },
    rfidChange(i) {
      if (!this.rfid_obj.rfid_no_list[i].rfid_no) {
        return;
      }

      //  判断 卡号是否重复
      let flag = false;
      this.rfid_obj.rfid_no_list.forEach((item, index) => {
        if (index != i) {
          if (item.rfid_no == this.rfid_obj.rfid_no_list[i].rfid_no) {
            flag = true;
          }
        }
      });
      if (flag) {
        this.rfid_obj.rfid_no_list[i].rfid_no = "";
        return this.$message.warning("rfid卡号重复");
      }
      get(openAPI.get_rfid_by_no, {
        rfid_no: this.rfid_obj.rfid_no_list[i].rfid_no,
      })
        .then((res) => {
          if (res.data.code == 0) {
            this.rfid_obj.rfid_no_list[i].rfid_no = "";
            this.$message.warning("该卡号已使用");
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
        });
    },
    editRfid(i) {
      this.rfid_obj.rfid_no_list.forEach((item) => {
        item.isDis = true;
      });

      this.rfid_obj.rfid_no_list[i].isDis = false;
      setTimeout(()=>{

      this.$nextTick(()=>{
          this.$refs['inpArea'+i].focus();
      })},200)
      
    },
    resRfid(i) {
      this.rfid_obj.rfid_no_list[i].isDis = true;
    },
  },
  computed: {
    // 新增数量总数
    totalNum() {
      let arr = this.rfid_obj.rfid_no_list.map(
        (item) => item.podr_prod_num - 0
      );
      return arr.reduce((pre, cur) => {
        return (pre += cur);
      }, 0);
    },
    notTotal() {
      return this.addCardForm.prod_num - this.totalNum >= 0
        ? this.addCardForm.prod_num - this.totalNum
        : 0;
    },
  },
};
</script>

<style  lang="scss" scoped>
.active {
  color: red;
}
.vd_mt_20 {
  margin-top: 20px;
}
.vd_bt {
  border-top: 1px dashed #ccc;
  padding-top: 15px;
}


</style>  

