<template>
  <div>
      <OpenCartAddMain/>
  </div>
</template>

<script>
import OpenCartAddMain from '@/views/ProduceManagement/openManage/TabChild/OpenCartAddMain.vue'
export default {
    name:'OpenCartAdd',
    components:{
        OpenCartAddMain
    }
}
</script>


<style lang="scss" scoped>

</style>